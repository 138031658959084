import React, { useContext, useEffect, useState } from "react";
import "./admin.css";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import CreateIcon from "@mui/icons-material/Create";
import AddIcon from "@mui/icons-material/Add";
import Avatar from "@mui/material/Avatar";
import { Context } from "../../Context/AuthContext";
import { getDocs, collection } from "firebase/firestore";
import { db } from "../../firebase-config";
import { Navigate } from "react-router-dom";
import { useNavigate } from "react-router-dom";

export function Admin() {
	const [posts, setPosts] = useState([]);
	const { user } = useContext(Context);

	let navigate = useNavigate();

	useEffect(() => {
		const fetchPosts = async () => {
			const querySnapshot = await getDocs(collection(db, "blog-posts"));

			const postsData = querySnapshot.docs.map((doc) => ({
				id: doc.id,
				...doc.data(),
			}));

			setPosts(postsData);
		};

		fetchPosts();
	}, []);

	const navigateToBlogEdit = (blogId) => {
		navigate(`/edit/blog/${blogId}`)
	}

	return (
		<>
			<Typography variant="h1">ADMIN CENTRE</Typography>

			<div className="admin-content-div">
				<div>
					{user.photoURL ? (
						<Avatar
							sx={{ width: 56, height: 56 }}
							src={user.photoURL}
							alt="author photo"></Avatar>
					) : (
						<Avatar sx={{ bgcolor: "gray", width: 56, height: 56 }}>C</Avatar>
					)}
				</div>
				<div id="admin-user-information">
					<Typography variant="body1">Welcome {user.displayName}</Typography>
					<Typography variant="body1">Email: {user.email}</Typography>
				</div>
			</div>

			<Typography variant="h2" id="admin-blogs-header">
				BLOGS
			</Typography>

			<div className="admin-content-div">
				<Button variant="contained" id="create-blog-button" onClick={() => navigate("/create/blog")}>
					Create Blog Post
					<AddIcon sx={{ paddingLeft: "10px", color: "#00000099" }} />
				</Button>

				<TableContainer component={Paper}>
					<Table sx={{ minWidth: 650 }} aria-label="simple table">
						<TableHead>
							<TableRow>
								<TableCell>Blog Id</TableCell>
								<TableCell>Blog Title</TableCell>
								<TableCell>Created Date</TableCell>
								<TableCell></TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{posts.map((row) => (
								<TableRow
									key={row.id}
									sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
									<TableCell component="th" scope="row">
										{row.id}
									</TableCell>
									<TableCell>{row.title}</TableCell>
									<TableCell>{row.blogCreatedDate}</TableCell>
									<TableCell align="right" className="blog-table-edit-column">
										<CreateIcon sx={{ color: "#00000099" }} onClick={() => navigateToBlogEdit(row.id)}/>
									</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>
			</div>
		</>
	);
}
