import React, { useContext, useState } from "react";
import { slide as Menu } from "react-burger-menu";
import "./mobileHeaderStyle.scss";
import Logo from "../Images/basic-logo.png";
import { Link } from "react-router-dom";
import HomeIcon from "@mui/icons-material/Home";
import Person2Icon from "@mui/icons-material/Person2";
import CameraRollIcon from "@mui/icons-material/CameraRoll";
import CollectionsIcon from "@mui/icons-material/Collections";
import ChatBubbleIcon from "@mui/icons-material/ChatBubble";
import EmailIcon from "@mui/icons-material/Email";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import InstagramLogo from "../Images/instagramLogo.png";
import FacebookLogo from "../Images/facebookLogo.png";
import TikTokLogo from "../Images/tikTokLogo.png";
import SettingsIcon from "@mui/icons-material/Settings";
import { Typography } from "@mui/material";
import { Context } from "../Context/AuthContext";

function MobileHeader() {
	const [showMenu, setShowMenu] = useState(false);
	const [menuOpen, setMenuOpen] = useState(false);

	const { user } = useContext(Context);

	return (
		<>
			<div>
				<img src={Logo} id="mobile-home-page-logo" alt="Stories by Chey Logo" />
			</div>

			<div className="headerMenuDivParent">
				<Menu
					isOpen={menuOpen}
					onOpen={() => setMenuOpen(true)}
					className="mobileHeader">
					<div className="mobileHeaderLogo">
						<img src={Logo} className="mobileLogo" alt="stories by chey logo" />
					</div>

					<Link
						to="/"
						className="mobile-header-link"
						onClick={() => setMenuOpen(!menuOpen)}>
						<div className="mobile-header-link-text">
							<HomeIcon className="mobile-header-link-icon" />
							<Typography>HOME</Typography>
						</div>
					</Link>

					<Link
						to="/about"
						className="mobile-header-link"
						onClick={() => setMenuOpen(!menuOpen)}>
						<div className="mobile-header-link-text">
							<Person2Icon className="mobile-header-link-icon" />
							<Typography>ABOUT</Typography>
						</div>
					</Link>

					<div className="mobile-header-link">
						<div
							className="mobile-header-link-text"
							onClick={() => setShowMenu(!showMenu)}>
							<CameraRollIcon className="mobile-header-link-icon" />
							<Typography>PACKAGES</Typography>
							<KeyboardArrowDownIcon sx={{ color: "#00000099" }} />
						</div>
						{showMenu && (
							<div>
								<Link
									to="/packages"
									className="mobile-header-link"
									onClick={() => setMenuOpen(!menuOpen)}>
									<div className="mobile-header-link-text">
										<Typography>WEDDING</Typography>
									</div>
								</Link>
								<Link
									to="/lifestyle"
									className="mobile-header-link"
									onClick={() => setMenuOpen(!menuOpen)}>
									<div className="mobile-header-link-text">
										<Typography>LIFESTYLE</Typography>
									</div>
								</Link>
							</div>
						)}
					</div>

					<Link
						to="/portfolio"
						className="mobile-header-link"
						onClick={() => setMenuOpen(!menuOpen)}>
						<div className="mobile-header-link-text">
							<CollectionsIcon className="mobile-header-link-icon" />
							<Typography>PORTFOLIO</Typography>
						</div>
					</Link>

					<Link
						to="/contactMe"
						className="mobile-header-link"
						onClick={() => setMenuOpen(!menuOpen)}>
						<div className="mobile-header-link-text">
							<EmailIcon className="mobile-header-link-icon" />
							<Typography>CONTACT</Typography>
						</div>
					</Link>

					<Link
						to="/blog"
						className="mobile-header-link"
						onClick={() => setMenuOpen(!menuOpen)}>
						<div className="mobile-header-link-text">
							<ChatBubbleIcon className="mobile-header-link-icon" />
							<Typography>BLOG</Typography>
						</div>
					</Link>

					{user !== null && (
						<Link
							to="/admin"
							className="mobile-header-link"
							onClick={() => setMenuOpen(!menuOpen)}>
							<div className="mobile-header-link-text">
								<SettingsIcon className="mobile-header-link-icon" />
								<Typography>ADMIN</Typography>
							</div>
						</Link>
					)}

					<div className="socialLinks" id="mobile-header-social-links">
						<a
							href="https://instagram.com/storiesbychey_weddings?igshid=NTc4MTIwNjQ2YQ=="
							className="socialImages">
							<img width="40px" src={InstagramLogo} alt="Instagram" />
						</a>
						<a
							href="https://m.facebook.com/Cheyyffaithhshots-102575778327589/"
							className="socialImages">
							<img width="40px" src={FacebookLogo} alt="Facebook" />
						</a>
						<a
							href="https://www.tiktok.com/@storiesbychey"
							className="socialImages">
							<img width="40px" src={TikTokLogo} alt="TikTok" />
						</a>
					</div>

					<div className="mobile-header-copyright-text">
						<Typography sx={{ textAlign: "center" }} variant="body1">
							&copy; storiesbychey 2024 - all rights reserved
						</Typography>
					</div>
				</Menu>
			</div>
		</>
	);
}

export default MobileHeader;
