import React, { useState } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import "./contactForm.scss";
import LoadingButton from "@mui/lab/LoadingButton";
import emailIcon from "../Images/emailIcon.png";
import locationIcon from "../Images/locationIcon.png";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import GlobeIcon from "../Images/globe-icon.png";
import axios from "axios";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import DialogTitle from "@mui/material/DialogTitle";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import Typography from "@mui/material/Typography";

dayjs.extend(utc);
dayjs.extend(timezone);

const ContactForm = () => {
	const functionUrl = `${process.env.REACT_APP_AZURE_FUNCTION_URL}`;

	const [open, setOpen] = useState(false);
	const [service, setService] = useState(10);
	const [serviceDate, setServiceDate] = useState(dayjs.utc());

	const handleOpen = () => {
		setOpen(true);
	};
	const handleClose = () => setOpen(false);

	const [isSubmittingForm, setIsSubmittingForm] = useState(false);

	const handleSubmit = async (e) => {
		e.preventDefault();
		setIsSubmittingForm(true);

		const { firstName, lastName, phone, email, message, weddingLocation } =
			e.target.elements;

		let serviceToString = "Other";

		if (service === 10) {
			serviceToString = "Wedding";
		} else if (service === 20) {
			serviceToString = "Engagement";
		} else if (service === 30) {
			serviceToString = "Maternity";
		} else if (service === 40) {
			serviceToString = "Family";
		}

		await axios.post("/api/sendEmailEnquiry", {
			firstName: firstName.value,
			lastName: lastName.value,
			phoneNumber: phone.value,
			email: email.value,
			comments: message.value,
			serviceType: serviceToString,
			date: serviceDate,
			location: service === 10 ? weddingLocation.value : "N/A",
		});

		document.getElementById("contact-form").reset();
		setServiceDate(dayjs.utc());
		setIsSubmittingForm(false);
		handleOpen();
	};

	const handleServiceChange = (event) => {
		setService(event.target.value);
	};

	return (
		<LocalizationProvider dateAdapter={AdapterDayjs}>
			<div className="contactForm">
				<Card className="formCard">
					<CardContent>
						<Typography variant="h1" className="contactMeTitle">
							Let's get in touch!
						</Typography>
						<form id="contact-form" onSubmit={handleSubmit} method="POST">
							<Grid container spacing={1}>
								<Grid xs={12} item>
									<TextField
										id="firstName"
										label="First Name"
										variant="outlined"
										required
										fullWidth
									/>
								</Grid>
								<Grid xs={12} item>
									<TextField
										id="lastName"
										label="Last Name"
										variant="outlined"
										fullWidth
										required
									/>
								</Grid>
								<Grid xs={12} item>
									<TextField
										id="phone"
										label="Phone Number"
										variant="outlined"
										type="number"
										fullWidth
										required
									/>
								</Grid>
								<Grid xs={12} item>
									<TextField
										id="email"
										label="Email"
										variant="outlined"
										type="email"
										fullWidth
										required
									/>
								</Grid>
								<Grid xs={12} item>
									<FormControl fullWidth>
										<InputLabel id="select-service-label">
											Shoot Type
										</InputLabel>
										<Select
											labelId="select-service-label"
											id="select-service-drop-down"
											value={service}
											label="Service"
											onChange={handleServiceChange}>
											<MenuItem value={10}>Wedding</MenuItem>
											<MenuItem value={20}>Engagement</MenuItem>
											<MenuItem value={30}>Maternity</MenuItem>
											<MenuItem value={40}>Family</MenuItem>
											<MenuItem value={50}>Other</MenuItem>
										</Select>
									</FormControl>
								</Grid>
								<Grid xs={12} item>
									<DatePicker
										format="DD/MM/YYYY"
										timezone="Australia/Brisbane"
										label={service === 10 ? "Wedding Date" : "Prefered Date"}
										value={serviceDate}
										onChange={(newValue) => setServiceDate(newValue)}
										slotProps={{ textField: { fullWidth: true } }}
									/>
								</Grid>
								{service === 10 ? (
									<Grid xs={12} item>
										<TextField
											id="weddingLocation"
											label="Wedding Location"
											variant="outlined"
											fullWidth
											required
										/>
									</Grid>
								) : null}
								<Grid xs={12} item>
									<TextField
										id="message"
										label={
											service === 10
												? "Tell me about your wedding"
												: "Tell me more"
										}
										variant="outlined"
										multiline
										rows={4}
										fullWidth
									/>
								</Grid>
								<Grid xs={8} sm={4} item>
									<LoadingButton
										className="contact-form-submit-button"
										loading={isSubmittingForm}
										type="submit"
										variant="contained"
										fullWidth>
										Submit
									</LoadingButton>
								</Grid>
							</Grid>
						</form>

						<div id="extra-contact-information">
							<Typography variant="h2" className="contactMeTitle">
								Contact information
							</Typography>

							<div className="contact-information">
								<ul>
									<li>
										<Typography variant="body1" className="extraContactMeText" textAlign="left">
											Brisbane, Sunshine Coast, Gold Coast, and Surroundings
										</Typography>
									</li>
									<li>
										<Typography variant="body1" className="extraContactMeText" textAlign="left">
											Available worldwide
										</Typography>
									</li>
									<li>
										<Typography variant="body1" className="extraContactMeText" textAlign="left">
											cheyyffaithhshots@gmail.com
										</Typography>
									</li>
								</ul>
							</div>
						</div>
					</CardContent>
				</Card>

				<Dialog onClose={handleClose} open={open}>
					<DialogTitle>Form Submitted</DialogTitle>
					<IconButton
						aria-label="close"
						onClick={handleClose}
						sx={{
							position: "absolute",
							right: 8,
							top: 8,
							color: (theme) => theme.palette.grey[500],
						}}>
						<CloseIcon />
					</IconButton>
					<DialogContent>
						Thank you for getting in contact with me! I am looking forward to
						speaking with you more.
					</DialogContent>
				</Dialog>
			</div>
		</LocalizationProvider>
	);
};

export default ContactForm;
