import React from "react";
import "./portfolio.scss";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import { Helmet } from "react-helmet";

const columnsCountBreakPoints = { 350: 1, 750: 2, 900: 3 };

function importAll(r) {
	return r.keys().map(r);
}

const images = importAll(
	require.context("../Images/Portfolio", false, /\.(png|jpe?g|svg)$/)
);

function Portfolio() {
	return (
		<>
			<Helmet>
				<title>Portfolio | Sunshine Coast Photographer</title>
				<meta
					name="description"
					content="Portfolio Gallery by Stories By Chey"
				/>
			</Helmet>

			<div className="masonaryDiv">
				<ResponsiveMasonry columnsCountBreakPoints={columnsCountBreakPoints}>
					<Masonry gutter={4}>
						{images.map((image) => (
							<img
								src={image}
								alt="portfolio image"
								className="portfolioMasonryImages"
							/>
						))}
					</Masonry>
				</ResponsiveMasonry>
			</div>
		</>
	);
}

export default Portfolio;
