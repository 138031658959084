import React from "react";
import "./footer.scss";
import InstagramLogo from "../Images/instagramLogo.png";
import FacebookLogo from "../Images/facebookLogo.png";
import Logo from "../Images/basic-logo.png";
import TikTokLogo from "../Images/tikTokLogo.png";
import { Typography } from "@mui/material";

function Footer() {
	return (
		<div className="contatiner">
			<div className="socialLinks">
				<a
					href="https://instagram.com/storiesbychey_weddings?igshid=NTc4MTIwNjQ2YQ=="
					className="socialImages">
					<img width="50px" src={InstagramLogo}></img>
				</a>
				<a
					href="https://m.facebook.com/Cheyyffaithhshots-102575778327589/"
					className="socialImages">
					<img width="50px" src={FacebookLogo}></img>
				</a>
				<a 
					href="https://www.tiktok.com/@storiesbychey"
					className="socialImages">
						<img width="50px" src={TikTokLogo}></img>
					</a>
			</div>

			<div >
				<img id="footer-logo-div" src={Logo} />
			</div>

			<div className="footerInstagramHandle">
				<Typography variant="h2" id="at-symbol">@</Typography>
				<Typography variant="h2" id="instagram-handle">STORIESBYCHEY</Typography>
			</div>

			<div className="footerCopyRightText">
				<Typography sx={{textAlign: "center"}} variant="body1">copyright &copy; storiesbychey 2024 - all rights reserved</Typography>
			</div>
		</div>
	);
}

export default Footer;
