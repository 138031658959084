import React, { useEffect, useState } from "react";
import Header from "./header";
import MobileHeader from "./mobileHeader";

function HeaderController(props) {
  const [width, setWidth] = useState(window.innerWidth);
  const breakpoint = 1025;

  useEffect(() => {
    window.addEventListener("resize", () => setWidth(window.innerWidth));
  }, []);

  return width < breakpoint ? (
    <MobileHeader />
  ) : (
    <Header nameofPage={props.nameofPage} />
  );
}

export default HeaderController;
