import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import BlogCard from "../../Components/BlogCard/BlogCard";
import {
	Grid,
	Box,
	OutlinedInput,
	InputAdornment,
	FormControl,
	InputLabel,
	Typography,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import "./Blog.css";
import { getDocs, collection } from "firebase/firestore";
import { db } from "../../firebase-config";

const BlogCardData = [
	{
		id: 1,
		title: "Fun wedding photo ideas",
		readTime: "7",
		image: "wedding-package1",
	},
	{
		id: 2,
		title: "Top 10 Sunshine Coast Wedding Venues",
		readTime: "15",
		image: "wedding-package2",
	},
	{
		id: 3,
		title: "Why you should enlope",
		readTime: "5",
		image: "wedding-package3",
	},
];

function Blog() {
	const [postList, setPostList] = useState([]);
	const blogPostsCollectionRef = collection(db, "blog-posts");

	useEffect(() => {
		const getPosts = async () => {
			const data = await getDocs(blogPostsCollectionRef);
			setPostList(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
		};

		getPosts();
	});

	return (
		<>
			<Helmet>
				<title>
					Wedding, Photography, and Lifestyle Blogs by Stories By Chey
					Photography
				</title>
				<meta
					name="description"
					content="Your one stop for blogs related to Weddings, Engagements, Photography, Travel, and Lifestyle"
				/>
			</Helmet>

			<div id="blog-post-container">
				{/* <FormControl id="blog-search-form-control" variant="outlined">
					<InputLabel htmlFor="blog-search-input">Search...</InputLabel>
					<OutlinedInput
						endAdornment={
							<InputAdornment position="end">
								<SearchIcon />
							</InputAdornment>
						}
						label="Search..."
					/>
				</FormControl> */}

				{postList.length === 0 ? (
					<div> <Typography variant="body1">No blog posts yet, please come back soon</Typography></div>
				) : (
					<Box sx={{ flexGrow: 1 }}>
						<Grid container justifyContent="center" spacing={1}>
							{postList.map((blog) => (
								<BlogCard key={blog.id} blog={blog} />
							))}
						</Grid>
					</Box>
				)}
			</div>
		</>
	);
}

export default Blog;
