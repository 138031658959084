import react from "react";
import "./Home/home.scss";
import "./about.scss";
import Chey from "../Images/about-me.jpg";
import { Helmet } from "react-helmet";
import { Typography } from "@mui/material";

function about() {
	return (
		<div className="pagePaddingTop">
			<Helmet>
				<title>About | Stories By Chey Photography</title>
				<meta
					name="description"
					content="Hello gorgeous people, I am a Brisbane/Sunshine Coast based wedding and lifestyle photograher with a passion for bringing special events to life through the power of photography. "
				/>
			</Helmet>

			<div className="about-me-page-div">
				<div id="aboutMeImageDiv">
					<img src={Chey} className="aboutImage"></img>
				</div>
				<div className="aboutPageAboutChey">
					<Typography variant="h1">MEET THE PHOTOGRAPHER</Typography>
					<Typography variant="body1">
						★ I’m so glad you’re here ★<br></br>
						I’m the face behind the lens with a name you probably won’t
						pronounce right on the first try—thanks, Mum and Dad! I’m a wife,
						mum, and creative with a passion for capturing moments that last a
						lifetime.<br></br>
						<br></br>
						Based between Brisbane and the Sunshine Coast (but always happy to
						travel worldwide!), Stories by Chey specialises in lifestyle and
						wedding photography.<br></br>
						<br></br>
						Growing up, there was always a camera nearby—whether it was
						capturing my childhood or preserving moments I didn’t want to
						forget, a camera was always within arm’s reach. This sparked a
						creative fire in me that I couldn’t ignore. For a long time, I
						didn’t think photography was something I could pursue, but then I
						realised life’s too short not to do what you love. Now, I get to
						share my art with the world!<br></br>
						<br></br>
						The heart behind Stories by Chey has always been to build a family
						business that offers not just affordable, timeless photography but
						also meaningful connections. My goal is to be there for
						you—capturing everything from life’s biggest milestones to the
						quiet, everyday moments in between. I’m so grateful you’re
						considering me and can’t wait to be part of your journey, wherever
						it takes us!<br></br>
						<br></br>
						Chey ‪‪x
					</Typography>
				</div>
			</div>
		</div>
	);
}

export default about;
