import ContactForm from "../Components/contactForm";
import "./contactMe.scss";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Helmet } from "react-helmet";

function ContactMe() {
  return (
    <div className="contactMePageBG pagePaddingTop">
      <Helmet>
				<title>Contact | Stories By Chey - Sunshine Coast Photographer</title>
				<meta
					name="description"
					content="Contact Sunshine Coast Wedding Photographer"
				/>
			</Helmet>

      <ContactForm />
    </div>
  );
}

export default ContactMe;
