import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import { db } from "../../firebase-config";
import CircularProgress from "@mui/material/CircularProgress";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import "./BlogPost.css";
import { doc, getDoc } from "firebase/firestore";
import { Link } from "react-router-dom";
import { formatHumanReadableDate } from "../../utils/dateUtils";

function BlogPost() {
	const { blogId } = useParams();
	const [blogData, setBlogData] = useState({});

	useEffect(() => {
		const getPosts = async () => {
			const blogPostRef = doc(db, "blog-posts", blogId);
			const data = await getDoc(blogPostRef);
			setBlogData(data.data());
		};

		getPosts();
	}, []);

	return (
		<>
			<Helmet>
				<title>{blogData.title}</title>
				<meta name="description" content={blogData.subTitle} />
			</Helmet>

			<div className="blog-back-button">
				<Link to="/blog" className="blog-back-link">
					<ArrowBackIosIcon sx={{ color: "#00000099" }} />
					<Typography>Back to Blogs</Typography>
				</Link>
			</div>

			{blogData.title ? (
				<div id="blog-post">
					<Typography variant="h3">{blogData.title}</Typography>
					<Typography id="blog-subtitle-header" variant="h6">
						{blogData.subTitle}
					</Typography>

					<div className="blog-author-div">
						{/* {blogData.author.avatarPhoto ? (
							<Avatar
								sx={{ width: 56, height: 56 }}
								src="https://lh3.googleusercontent.com/a/ACg8ocL8IK_xImzGhz3aLgkIpbWDZG3jfhaQ47BD26YhD6715A-u6h8=s96-c"
								alt="author photo"></Avatar>
						) : (
							<Avatar sx={{ bgcolor: "gray", width: 56, height: 56 }}>CD</Avatar>
						)} */}
						<Avatar sx={{ bgcolor: "gray", width: 56, height: 56 }}>CD</Avatar>
						<div className="blog-author-name">
							<Typography variant="body1" sx={{ textAlign: "left" }}>
								{blogData.author.name}
							</Typography>
							<Typography variant="body1" sx={{ textAlign: "left" }}>
								{blogData.readTime} min read .{" "}
								{blogData.blogCreatedDate &&
									formatHumanReadableDate(blogData.blogCreatedDate)}
							</Typography>
						</div>
					</div>

					<div
						id="blog-text-div"
						dangerouslySetInnerHTML={{ __html: blogData.text }}
					/>

					<div id="blog-tag-container-div">
						{blogData.tags.split(",").map((tag) => (
							<div className="blog-tag-div">
								<Typography variant="h6">{tag}</Typography>
							</div>
						))}
					</div>
				</div>
			) : (
				<div id="blog-loading-div">
					<CircularProgress sx={{ color: "black" }} />
				</div>
			)}
		</>
	);
}

export default BlogPost;
