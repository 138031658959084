import React, { useContext } from "react";
import { Navigate } from "react-router-dom";
import { Context } from "../Context/AuthContext";

function ProtectedRoute({ children }) {
	const { user } = useContext(Context);
	console.log("test protected route new ", user);

	if (!user) {
		return <Navigate to="/login" />;
	} else {
		return children;
	}
}

export default ProtectedRoute;
