import React, { useState } from "react";
import Chey from "../../Images/about-me.jpg";
import HomePageCollage from "../../Images/home-page-collage.jpg";
import HomePageArrow from "../../Images/home-page-arrow.png";
import "./home.scss";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import Slider from "../../Components/slider";
import { Helmet } from "react-helmet";
import Typography from "@mui/material/Typography";
import Image1 from "../../Images/header-image.jpg";
import Image2 from "../../Images/wedding-package2.jpg";
import Wedding from "../../Images/homepage-wedding.jpg";
import Lifestyle from "../../Images/homepage-lifestyle.jpg";

function New() {
	const [width, setWidth] = useState(window.innerWidth);

	const isMobile = width <= 768;

	return (
		<div>
			<Helmet>
				<title>Stories By Chey | Sunshine Coast Photographer</title>
				<meta
					name="description"
					content="Brisbane & Sunshine Coast based wedding & lifestyle photographer helping you create memories that will last a life time"
				/>
			</Helmet>

			<div>
				<div className="headerImageContainer">
					{isMobile ? (
						<div id="mobile-home-page-header">
							<img
								className="headerSliderImages"
								src={Image2}
								alt="Stories by Chey wedding image"
							/>
						</div>
					) : (
						<div id="headerImageSliderContainer">
							<img
								className="headerSliderImages"
								src={Image1}
								alt="Stories by Chey wedding image"
							/>
						</div>
					)}
				</div>
			</div>

			<div id="home-page-image-collage-div">
				<img alt="image Collage" width="100%" height="100%" src={HomePageCollage}></img>
			</div>
			<div id="home-page-content">
				<div className="about-div">
					<div className="about-image-div">
						<img src={Chey} alt="about-image" className="homepage-images"></img>
					</div>

					<div className="about-text-div">
						<div id="about-me-text">
							<Typography variant="h1">
								BRISBANE & SUNSHINE COAST WEDDING AND LIFESTYLE PHOTOGRAPHER
							</Typography>
							<Typography variant="body1">
								hey lovely! thanks for stopping by, I’m the face behind the lens
								here capturing moments to last forever. so stay a while, have a
								browse and get to know me better through my pictures that speak
								a thousand words
								<br />
								xx
							</Typography>
							<a href="/about">
								<Typography variant="body1">Read More ></Typography>
							</a>
						</div>
					</div>
				</div>

				<div className="about-div">
					<div className="about-image-div">
						<img
							src={Lifestyle}
							alt="lifestyle-image"
							className="homepage-images"></img>
					</div>

					<div className="about-text-div">
						<Typography variant="h2">Couples</Typography>
						<Typography variant="h2">Maternity</Typography>
						<Typography variant="h2">Family</Typography>
						<a href="/lifestyle">
							<Typography variant="body1">Our Packages ></Typography>
						</a>
					</div>
				</div>
				<div className="about-div">
					<div className="about-image-div">
						<img
							src={Wedding}
							alt="wedding-image"
							className="homepage-images"></img>
					</div>

					<div className="about-text-div">
						<Typography variant="h2">Elopements</Typography>
						<Typography variant="h2">Intimate Weddings</Typography>
						<Typography variant="h2">Weddings</Typography>
						<a href="/packages">
							<Typography variant="body1">Our Packages ></Typography>
						</a>
					</div>
				</div>
				<div id="sessions-offered-div">
					<div className="floatContainer">
						<Typography variant="h2">WHAT SESSIONS DO I OFFER?</Typography>
					</div>
					<div className="floatContainer">
						<img src={HomePageArrow} alt="arrow" />
					</div>
					<div className="floatContainer">
						<div className="photography-services-div">
							<Typography variant="h2">LIFESTYLE</Typography>
							<Typography variant="body1">
								Whether you're newly engaged, expecting, in love, new parents or
								just needing to update those old family photos, I've got you
								covered!
							</Typography>
						</div>

						<div className="photography-services-div">
							<Typography variant="h2">WEDDINGS</Typography>
							<Typography variant="body1">
								Whether you're newly engaged, in the middle of planning or in
								your final steps of finding your professional 3rd wheel for your
								upcoming day, I'm here to help every step of the way! I am here
								to not only capture your day, but also help make your day go as
								smooth as possible. If you're hoping your day is filled with
								genuine human connections, candid love and lots of laughter, hit
								the contact button, I would love to chat more!
							</Typography>
						</div>

						<div className="photography-services-div">
							<Typography variant="h2">ELOPEMENTS</Typography>
							<Typography variant="body1">
								To run away secretly with the intention of getting married...
								sounds pretty fun to me, I'm in! Let me bring my camera along
								whilst we're at it to capture the fun loving couple you are. Pop
								over to my 'contact page, tell me a bit about yourselves and
								let's get planning!
							</Typography>
						</div>
					</div>
				</div>
				<div className="testHeader">
					<Typography variant="h2">TESTIMONIALS</Typography>
				</div>
				<div>
					<Slider />
				</div>
			</div>
		</div>
	);
}

export default New;
