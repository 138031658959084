import React from "react";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import "./BlogPreview.css";
import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";

function BlogPreview({ blogData, closePreview}) {
	return (
		<div id="preview-blog-div">
			<div>
				<Button id="preview-blog-close-button" onClick={closePreview}>
					<CloseIcon color="button" />
				</Button>
			</div>

			<div id="preview-blog-content-div">
				<Typography variant="h3">{blogData.title}</Typography>
				<Typography id="blog-subtitle-header" variant="subtitle1">
					{blogData.subTitle}
				</Typography>

				<div className="blog-author-div">
					<Avatar sx={{ bgcolor: "gray" }}>N</Avatar>
					<div className="blog-author-name">
						<Typography variant="body1">{blogData.author.name}</Typography>
						<Typography variant="body1">
							{blogData.readTime} min read . 20th Januray 2024
						</Typography>
					</div>
				</div>

				<div id="blog-text-div">
					<div dangerouslySetInnerHTML={{ __html: blogData.text }} />
				</div>

				<div id="blog-tag-container-div">
					{blogData.tags.split(",").map((tag) => (
						<div className="blog-tag-div">
							<Typography variant="body2">{tag}</Typography>
						</div>
					))}
				</div>
			</div>
		</div>
	);
}

export default BlogPreview;
