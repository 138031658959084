import * as React from "react";
import "./packageItem.scss";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

function packageItem(
	packageTitle,
	coverage,
	pricing,
	packageInclusions,
	packageImage,
	isFeaturedPackage
) {
	return (
		<div className="package-container">
			<img src={packageImage} className="package-image" alt={packageTitle} />

			{isFeaturedPackage && (
				<Typography variant="h1" className="popular-package-text">
					Our most popular package
				</Typography>
			)}

			<div
				className={`${
					isFeaturedPackage ? "featured-package-div" : ""
				} package-info-div`}>
				<Typography variant="h1" className="package-title">
					{packageTitle}
				</Typography>

				<Typography variant="h5" className="package-coverage">
					{coverage}
				</Typography>

				{packageInclusions.map((item) => {
					return (
						<Typography className="package-inclusion-item" variant="body1">
							{item}
						</Typography>
					);
				})}

				<div className="package-pricing-booking-div">
					<Typography variant="h5" className="package-pricing">
						Starting from {pricing}
					</Typography>
				</div>

				<div className="package-book-button">
					<Button
						href="/contactMe"
						variant={isFeaturedPackage ? "contained" : "outlined"}
						className={isFeaturedPackage ? "package-contained-button" : "package-outlined-button"}>
						<Typography variant="body1">Book Now</Typography>
					</Button>
				</div>
			</div>
		</div>
	);
}

export default packageItem;
