import { Button, Typography } from "@mui/material";
import { provider } from "../../firebase-config";
import {
	signInWithPopup,
	setPersistence,
	getAuth,
	browserSessionPersistence,
	signOut,
} from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { Paper } from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useContext, useEffect } from "react";
import "./Login.scss";
import GoogleIcon from "@mui/icons-material/Google";
import { Context } from "../../Context/AuthContext";

const LoginDiv = styled(Paper)(({ theme }) => ({
	backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#faf9f6",
	...theme.typography.body1,
	padding: theme.spacing(3),
	color: theme.palette.text.secondary,
	margin: "auto",
	borderRadius: "6px",
	padding: "5% 3% 3% 3%",
	marginBottom: "100px",
	marginTop: "100px",
	width: "fit-content",
}));

function Login() {
	let navigate = useNavigate();

	const auth = getAuth();
	const { user } = useContext(Context);

	const signInWithGoogle = () => {
		setPersistence(auth, browserSessionPersistence);
		signInWithPopup(auth, provider).then((result) => {
			navigate("/admin");
		});
	};

	const handleSignOut = () => {
		signOut(auth);
	};

	return (
		<>
			{user !== null ? (
				<div id="logged-in-div">
					<Typography variant="h1">
						You are already logged in as {user.displayName}!
					</Typography>
					<Button
						id="sign-out-button"
						variant="contained"
						onClick={handleSignOut}>
						Sign out
					</Button>
				</div>
			) : (
				<LoginDiv
					className="login-page"
					sx={{ backgroundColor: "transparent", boxShadow: "none" }}>
					<Typography variant="h1">Sign in with Google</Typography>
					<div className="login-with-google-btn-div">
						<Button
							variant="contained"
							className="login-with-google-btn"
							onClick={signInWithGoogle}
							startIcon={<GoogleIcon color="secondary" />}>
							sign in with Google
						</Button>
					</div>
				</LoginDiv>
			)}
		</>
	);
}

export default Login;
