import React, { useContext, useEffect, useRef, useState } from "react";
import "./imageSliderAndMenu.scss";
import Logo from "../Images/logo-with-text.png";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Context } from "../Context/AuthContext";

function Menu(props) {
	const [scroll, setScroll] = useState(true);

	// useEffect(() => {
	// 	window.addEventListener("scroll", () => {
	// 		setScroll(window.scrollY > 50);
	// 	});
	// }, []);

	const { user } = useContext(Context);

	const page = props.nameofPage;
	const headerDivName = page ? "homepageHeader" : "headerMenu";

	const [showMenu, setShowMenu] = useState(false);

	function showPackageMenu() {
		setShowMenu(true);
	}

	function hidePackageMenu() {
		setShowMenu(false);
	}

	const node = useRef();

	return (
		<div className={scroll ? "stickyHeaderMenu" : headerDivName}>
			<div className="logoDiv">
				<img
					src={Logo}
					className={scroll ? "headerLogoSticky" : "headerLogo"}
				/>
			</div>

			<div
				id="header-links"
				className={scroll ? "homepageMenuSticky" : "homepageMenu"}>
				<div className="centered-links">
					<Link className="headerTitleLinks" to="/">
						<Typography>HOME</Typography>
					</Link>

					<Link className="headerTitleLinks" to="/about">
						<Typography>ABOUT</Typography>
					</Link>

					<div
						onMouseOver={showPackageMenu}
						onMouseLeave={hidePackageMenu}
						className="packagesDivMain"
						ref={node}>
						<a className="headerTitleLinks">
							<div className="packages-header-link">
								<Typography>PACKAGES</Typography>
								<KeyboardArrowDownIcon sx={{ color: "#00000099" }} />
							</div>
						</a>
						{showMenu ? (
							<div
								id="packages-drop-down-menu"
								className={`${scroll && "menuDropdown"} "menuDropdownSticky"`}>
								<div>
									<Link
										className="sub-menu-link headerTitleLinks"
										to="/packages">
										<Typography variant="body2">WEDDING</Typography>
									</Link>
								</div>
								<div>
									<Link
										className="sub-menu-link headerTitleLinks"
										to="/lifestyle">
										<Typography variant="body2">LIFESTYLE</Typography>
									</Link>
								</div>
							</div>
						) : null}
					</div>

					<Link className="headerTitleLinks" to="/portfolio">
						<Typography>PORTFOLIO</Typography>
					</Link>

					<Link className="headerTitleLinks" to="/contactMe">
						<Typography>CONTACT</Typography>
					</Link>

					<Link className="headerTitleLinks" to="/blog">
						<Typography>BLOG</Typography>
					</Link>
				</div>

				{user !== null && (
					<div className="admin-link">
						<Link className="headerTitleLinks" to="/admin">
							<Typography>ADMIN</Typography>
						</Link>
					</div>
				)}
			</div>
		</div>
	);
}

export default Menu;
