import * as React from "react";
import "./packageItem.scss";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

function lifeStylePackageItem(props) {
	return (
		<div className="lifestyle-package-container">
			<img src={props.image} className="package-image" alt={props.title} />

			<div className="package-info-div">
				<Typography variant="h1" className="package-title">
					{props.title}
				</Typography>

				{props.inclusions.map((item) => {
					return (
						<Typography className="package-inclusion-item" variant="body1">
							{item}
						</Typography>
					);
				})}

				<div className="package-pricing-booking-div">
					<Button
						href="/contactMe"
						variant="outlined"
						className="package-outlined-button">
						<Typography variant="body1">Book Now</Typography>
					</Button>
				</div>
			</div>
		</div>
	);
}

export default lifeStylePackageItem;
