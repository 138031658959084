import React from "react";
import { styled } from "@mui/material/styles";
import { Paper, Grid } from "@mui/material";
import Typography from '@mui/material/Typography';
import "./BlogCard.css";

const Item = styled(Paper)(({ theme }) => ({
	backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#faf9f6",
	...theme.typography.body1,
	padding: theme.spacing(3),
	textAlign: "left",
	color: theme.palette.text.secondary,
	margin: " 5rem 1rem 0 1rem",
	borderRadius: "6px",
	paddingBottom: "5%",
}));

const Img = styled("img")(({ theme }) => ({
	width: "100%",
	height: "100%",
	objectFit: "contain",
	borderRadius: "3px",
	paddingBottom: "5%",
}));

function BlogCard({ blog }) {

	const navigateToPostPage = () => {
		window.location = `/blog/${blog.id}`
	}

	return (
		<Grid item xs={12} md={6} lg={4} xl={4}>
			<Item className="blog-item" onClick={navigateToPostPage}>
				<Img src={blog.heroImage} alt="blog-image"/>
				<Typography variant="h5">{blog.title}</Typography>
				<Typography>{blog.readTime}min read</Typography>
			</Item>
		</Grid>
	);
}

export default BlogCard;
