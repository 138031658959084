export function getCurrentFormattedDate() {
    const date =  new Date();

    const year =  date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');

    return `${year}-${month}-${day}`
}

export function formatHumanReadableDate(dateString) {
    const months = [
      "January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"
    ];
  
    const date = new Date(dateString);
    const day = date.getDate();
    const month = months[date.getMonth()];
    const year = date.getFullYear();
  
    const getOrdinalSuffix = (day) => {
      if (day > 3 && day < 21) return 'th'; // For 11th, 12th, 13th, etc.
      switch (day % 10) {
        case 1: return "st";
        case 2: return "nd";
        case 3: return "rd";
        default: return "th";
      }
    };
  
    return `${day}${getOrdinalSuffix(day)} ${month} ${year}`;
  }